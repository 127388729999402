<template>
  <v-app class="transparent">
    <router-view />
  </v-app>
</template>

<script>
  export default {
    name: "Index"
  }
</script>

<style scoped>

</style>
